.container {
  width: 1140px;
  margin: 0px auto;
  max-width: 100%;
  min-height: 100%;
  min-height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
}
.flex-grid-1 {
  margin-right: 20px;
  width: calc((100% / 12) * 1 - 20px);
  min-width: calc((100% / 12) * 1 - 20px);
}
.flex-grid-1.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 1 - 0px);
  min-width: calc((100% / 12) * 1 - 0px);
}
.flex-grid-2 {
  margin-right: 20px;
  width: calc((100% / 12) * 2 - 20px);
  min-width: calc((100% / 12) * 2 - 20px);
}
.flex-grid-2.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 2 - 0px);
  min-width: calc((100% / 12) * 2 - 0px);
}
.flex-grid-3 {
  margin-right: 20px;
  width: calc((100% / 12) * 3 - 20px);
  min-width: calc((100% / 12) * 3 - 20px);
}
.flex-grid-3.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 3 - 0px);
  min-width: calc((100% / 12) * 3 - 0px);
}
.flex-grid-4 {
  margin-right: 20px;
  width: calc((100% / 12) * 4 - 20px);
  min-width: calc((100% / 12) * 4 - 20px);
}
.flex-grid-4.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 4 - 0px);
  min-width: calc((100% / 12) * 4 - 0px);
}
.flex-grid-5 {
  margin-right: 20px;
  width: calc((100% / 12) * 5 - 20px);
  min-width: calc((100% / 12) * 5 - 20px);
}
.flex-grid-5.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 5 - 0px);
  min-width: calc((100% / 12) * 5 - 0px);
}
.flex-grid-6 {
  margin-right: 20px;
  width: calc((100% / 12) * 6 - 20px);
  min-width: calc((100% / 12) * 6 - 20px);
}
.flex-grid-6.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 6 - 0px);
  min-width: calc((100% / 12) * 6 - 0px);
}
.flex-grid-7 {
  margin-right: 20px;
  width: calc((100% / 12) * 7 - 20px);
  min-width: calc((100% / 12) * 7 - 20px);
}
.flex-grid-7.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 7 - 0px);
  min-width: calc((100% / 12) * 7 - 0px);
}
.flex-grid-8 {
  margin-right: 20px;
  width: calc((100% / 12) * 8 - 20px);
  min-width: calc((100% / 12) * 8 - 20px);
}
.flex-grid-8.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 8 - 0px);
  min-width: calc((100% / 12) * 8 - 0px);
}
.flex-grid-9 {
  margin-right: 20px;
  width: calc((100% / 12) * 9 - 20px);
  min-width: calc((100% / 12) * 9 - 20px);
}
.flex-grid-9.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 9 - 0px);
  min-width: calc((100% / 12) * 9 - 0px);
}
.flex-grid-10 {
  margin-right: 20px;
  width: calc((100% / 12) * 10 - 20px);
  min-width: calc((100% / 12) * 10 - 20px);
}
.flex-grid-10.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 10 - 0px);
  min-width: calc((100% / 12) * 10 - 0px);
}
.flex-grid-11 {
  margin-right: 20px;
  width: calc((100% / 12) * 11 - 20px);
  min-width: calc((100% / 12) * 11 - 20px);
}
.flex-grid-11.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 11 - 0px);
  min-width: calc((100% / 12) * 11 - 0px);
}
.flex-grid-12 {
  margin-right: 20px;
  width: calc((100% / 12) * 12 - 20px);
  min-width: calc((100% / 12) * 12 - 20px);
}
.flex-grid-12.last-flex {
  margin-right: 0px;
  width: calc((100% / 12) * 12 - 0px);
  min-width: calc((100% / 12) * 12 - 0px);
}
@media screen and (max-width: 1200px) {
  body {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .container {
    width: calc(100% - 80px);
    margin: 0px 40px;
  }
}
@media screen and (max-width: 900px) {
  .swapWrapper .container {
    margin: 0px;
    width: 100%;
  }
}
* {
  outline: none;
  -webkit-font-smoothing: antialiased;
}
html,
body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
body {
  min-height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
  flex-direction: column;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  overflow: auto;
}
img {
  min-height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
  display: block;
}
input {
  display: flex;
  flex: auto;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}
.flex {
  display: flex;
  min-height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
}
.flexAuto {
  flex: auto;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.flexWrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.contentWrapper {
  padding: 24px;
}
.universe-wrapper {
  position: relative;
}
.seperator {
  width: 1px;
  height: 24px;
  background-color: #e2665c;
  margin: 0px 8px;
}
.whiteBox {
  background-color: rgba(239, 239, 239, 0.05);
  padding: 24px;
}
.whiteBox .txRowWrapper {
  padding: 16px;
  border-bottom: 1px solid rgba(239, 239, 239, 0.05);
}
.whiteBox .txRowWrapper:last-child {
  border-bottom: 0px;
}
.heroWrapper {
  padding: 56px 0px 40px 0px;
}
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bebasneue/v2/JTUSjIg69CK48gW7PXooxW4.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
body p {
  margin: 0px;
  font-size: 14px;
  color: #efefef;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  font-family: "Bebas Neue", cursive;
}
body h1 {
  font-size: 64px;
  font-weight: 600;
  font-family: "Bebas Neue", cursive;
}
body h2 {
  font-size: 52px;
  font-weight: 400;
  font-family: "Bebas Neue", cursive;
}
body h3 {
  font-size: 42px;
  font-weight: 400;
  font-family: "Bebas Neue", cursive;
}
body h4 {
  font-size: 32px;
  font-weight: 400;
  font-family: "Bebas Neue", cursive;
}
body h5 {
  font-size: 28px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
body h6 {
  font-size: 18px;
  font-weight: 300;
  font-family: "Bebas Neue", cursive;
}
body p {
  margin: 0px;
  font-size: 14px;
}
body p small {
  color: #a5a8a9;
  font-size: 12px;
}
body a {
  color: inherit;
  text-decoration: none;
  font-size: 14px;
}
body .fadedText {
  color: #838383;
}
.inputWrapper {
  margin-top: 8px;
}
.inputWrapper input {
  border: none;
  padding: 16px;
}
.btn {
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  color: #efefef;
  padding: 8px 16px;
  font-family: "Poppins", sans-serif;
  box-shadow: none;
  transition: all ease 0.3s;
}
.btn.btn-outline {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid #e2665c;
}
.btn.btn-outline:hover {
  background-color: #e2665c;
}
.btn.btn-outline:disabled {
  opacity: 0.75;
}
.btn.btn-transparent {
  background-color: transparent;
  color: #e2665c;
  border: none;
}
.btn.btn-transparent:hover {
  background-color: rgba(226, 102, 92, 0.1);
}
.btn.btn-red {
  border: none;
  background-color: rgba(226, 102, 92, 0.2);
  color: #e2665c;
}
.btn.btn-red:hover {
  background-color: rgba(226, 102, 92, 0.3);
}
.btn.btn-red:active {
  background-color: rgba(226, 102, 92, 0.15);
}
.notifications-component {
  z-index: 1000000 !important;
}
.notifications-component a {
  text-decoration: none;
  font-size: 14px;
  display: flex;
  transition: all 0.3s ease;
  color: rgba(20, 20, 20, 0.5);
}
.notifications-component a:hover {
  color: #141414;
}
.notifier {
  padding: 24px;
  width: 100%;
  border-radius: 0px;
  background-color: #efefef;
}
.notifier h5 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 12px;
  color: #141414;
}
.notifier a {
  word-break: break-all;
  padding-top: 16px;
  font-size: 14px;
}
.notifier .notifierIcon {
  padding-right: 16px;
}
.notifier .notifierIcon svg {
  width: 24px;
  height: 24px;
}
.notifier p {
  font-size: 16px;
  font-weight: 500;
  color: #141414;
}
.notifier p a {
  font-weight: 300;
  padding-top: 0px;
  font-size: 12px;
}
.notifier.success {
  background-color: #12b258;
  color: #efefef;
}
.notifier.success p,
.notifier.success a,
.notifier.success h5 {
  color: #efefef;
}
.notifier.error {
  background-color: #f22b5a;
  color: #efefef;
}
.notifier.error p,
.notifier.error a,
.notifier.error h5 {
  color: #efefef;
}
body {
  background-color: #28262c;
  color: #efefef;
}
header p {
  color: #838383;
}
header p b {
  color: #efefef;
}
.swapWrapper .swapStatsWrapper {
  background-color: #312f35;
}
.swapWrapper .swapForm {
  background-color: #141414;
}
.inputWrapper {
  background-color: rgba(239, 239, 239, 0.05);
}
.inputWrapper input {
  background-color: transparent;
  color: #efefef;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
footer {
  background-color: #141414;
}
.redText {
  color: #e2665c;
}
.lendList {
  margin-bottom: 80px;
}
.lendList .lendListItem {
  margin-top: 24px;
}
.pageMainContent {
  margin: 96px 0px;
}
@media only screen and (max-width: 480px) {
  .pageMainContent {
    margin: 0px 0px;
  }
}
.pageMainContentHide {
  display: none;
}
.borrowList {
  margin-top: 24px;
  margin-bottom: 56px;
}
.borrowList .borrowListItem {
  width: [object Object];
  margin-right: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.borrowList .borrowListItem:hover {
  background-color: rgba(239, 239, 239, 0.1);
}
.borrowList .borrowListItem:nth-child(4n) {
  width: [object Object];
  margin-right: 0px;
}
.borrowList .borrowListItem .borrowListItemMeta {
  margin-top: 24px;
}
.borrowList .borrowListItem .borrowListItemMeta .row:first-child p {
  font-size: 18px;
}
.myLoansList {
  margin-bottom: 80px;
}
.myLoansList .myLoanItem {
  margin-top: 24px;
}
.myLoansList .myLoanItem .borrowedHead p:first-child {
  color: #838383;
}
.myLoansList .myLoanItem .borrowedHead > .row {
  margin: 8px 0px 40px 0px;
}
.myLoansList .myLoanItem .borrowedValueWrapper,
.myLoansList .myLoanItem .collateralValueWrapper {
  margin-bottom: 24px;
}
.myLoansList .myLoanItem .borrowedValueWrapper .flex-grid-6,
.myLoansList .myLoanItem .collateralValueWrapper .flex-grid-6 {
  background-color: rgba(239, 239, 239, 0.1);
  padding: 16px 0px;
}
.myLoansList .myLoanItem .borrowedValueWrapper .flex-grid-6 p,
.myLoansList .myLoanItem .collateralValueWrapper .flex-grid-6 p {
  font-size: 18px;
  color: #efefef;
}
.myLoansList .myLoanItem .collateralValueWrapper {
  margin-bottom: 8px;
}
.myLoansList .myLoanItem .collateralValueWrapper .flex-grid-6 {
  background-color: transparent;
  border: 1px solid rgba(239, 239, 239, 0.05);
}
.myLoansList .myLoanItem .borrowedProgress progress {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  margin-top: 8px;
}
.myLoansList .myLoanItem .borrowedProgress progress[value]::-webkit-progress-bar {
  border-radius: 99px;
  background-color: rgba(239, 239, 239, 0.1);
}
.myLoansList .myLoanItem .borrowedProgress progress[value]::-webkit-progress-value {
  background-color: #efefef;
  border-radius: 99px;
}
.myLoansList .myLoanItem .myLoanActions {
  margin-top: 40px;
}
.myLoansList .myLoanItem .myLoanActions .btn {
  margin-right: 16px;
}
.myLoansList .myLoanItem .myLoanActions .btn:last-child {
  margin-right: 0px;
}
.myLoansList .myLoanItem .collateralActions .btn {
  margin-right: 16px;
}
.myLoansList .myLoanItem .collateralActions .btn:last-child {
  margin-right: 0px;
}
.myLoansList .myLoanItem .collateralWrapper {
  border-top: 1px solid rgba(239, 239, 239, 0.05);
  border-bottom: 1px solid rgba(239, 239, 239, 0.05);
  border-right: 1px solid rgba(239, 239, 239, 0.05);
}
.myLoansList .myLoanItem .collateralWrapper .collateralContent {
  padding: 24px;
}
.myLoansList .myLoanItem .collateralWrapper .collateralMetaWrapper {
  padding: 16px;
  border: 1px solid rgba(239, 239, 239, 0.05);
}
.liquidationsFiltering {
  margin: 24px 0px 40px 0px;
}
.liquidationsFiltering .inputWrapper {
  margin: 0px;
}
.liquidationsFiltering .filterSwitch {
  border: 1px solid rgba(239, 239, 239, 0.1);
}
.liquidationsFiltering .filterSwitch p {
  margin-right: 8px;
}
.warningStripe {
  background-color: #d94f49;
}
.warningStripe p {
  color: white;
  padding: 16px;
}
.addressWalletWrapper {
  width: 100%;
  height: 100%;
}
.addressWalletWrapper .addressWrapper {
  height: 100%;
}
.addressWalletWrapper .addressWrapper:hover {
  background-color: rgba(239, 239, 239, 0.2);
}
.walletWrapper:hover {
  background-color: transparent !important;
}
